<template>
  <company-layout>
    <template v-slot:main>
      Regnskab
      <a @click="getVirkData()">XML</a>
    </template>
  </company-layout>
</template>
<script>
import { parseString } from "xml2js";

import companyLayout from "@/layouts/CompanyLayout.vue";
export default {
  components: {
    companyLayout
  },
  data() {
    return {
      virkData: null
    };
  },
  methods: {
    getVirkData() {
      const url = "http://regnskaber.virk.dk/40463314/amNsb3VkczovLzAzLzQ1LzNjLzA1LzQ0L2EyNzQtNDY5Yi04MmIyLTZiZmQ0ZjVhOTVhZg.xml";
      this.$axios
        .get(url, {})
        .then(response => {
          this.virkData = this.parseVirkXml(response.data);
        })
        .catch(error => {
          console.log(error);
        })
        .then(() => {
          // always executed
        });
    },
    parseVirkXml(xml) {
      parseString(xml, function(err, result) {
        console.dir(result);
      });
    }
  }
};
</script>
